import React from 'react'

import GeneralPageLayout from '../components/Layouts/GeneralPage'
import MainTextBlock from '../components/UI/MainTextBlock/MainTextBlock'
import SectionContainer from './../components/UI/Containers/SectionContainer'
import SectionHeader from './../components/UI/SectionHeader/SectionHeader'
import CapabilityVideo from '../components/UI/Capabilities/CapabilityVideo/CapabilityVideo'
import ExpertCapabilities from '../components/UI/Capabilities/ExpertCapabilities/ExpertCapabilities'
import ServiceIcon from './../images/icons/capabilities/IntegrateProductSupport.inline.svg'

const ProductSupportPage = () => {
  const pageInfo = {
    pageType: 'interior',
    title1: 'Integrated',
    title2: 'Product Support',
    breadcrumbs: ['Capabilities'],
    ctaButton: false,
    ctaButtonData: {
      text: '',
      url: '',
    },
  }

  return (
    <GeneralPageLayout pageData={pageInfo}>
      <div className="content">
        {/* Capabilities Video */}
        <SectionContainer
          id="serviceVideo"
          color="white"
          type="pressedEdgeRight"
          classes={['inlineChildren_half', 'capabilitySection']}
        >
          <section>
            <SectionHeader
              color="navy"
              alignment="left"
              size="med"
              boldBreak={true}
            >
              Integrating the <span>Full Spectrum</span>
            </SectionHeader>
            <MainTextBlock>
              <p>
                We provide integrated product support of complex systems and
                equipment in support of the warfighter. Our teams supply the
                people, skills, knowledge, tools, facilities, and technical data
                to establish and execute logistics policies, processes, and
                procedures.
              </p>
            </MainTextBlock>
          </section>
          <CapabilityVideo
            url="https://www.youtube.com/embed/YrSA_XBlzSA?yt:cc=on"
            title="Precise Integrated Product Support Informational Video"
          />
        </SectionContainer>

        {/* Logistics Map */}
        <SectionContainer
          id="logisticsMap"
          color="gray"
          type="fullWidth"
          classes={['centerContents']}
        ></SectionContainer>

        {/* Expert Capabilities */}
        <SectionContainer id="" color="white" type="pressedEdgeLeft">
          <ExpertCapabilities
            serviceName="Integrated Product Support"
            ServiceIcon={ServiceIcon}
            hexSize="small"
          />
        </SectionContainer>
      </div>
    </GeneralPageLayout>
  )
}

export default ProductSupportPage
